/**
 * @file WorkflowTask.js
 * @created_date Friday, Sep 01, 2023
 * @author Rafi Haidari <rafi.haidari@medimesh.de>
 * @Copyright © 2023 mediMESH. All rights reserved.
 * @description This component is a React class component responsible for rendering a task within a video workflow. It is designed to be part of a hierarchical structure, allowing tasks to have child tasks. The component manages the visual representation of tasks, including their appearance, interaction, and potential child tasks.
 * @module WorkflowTask
 *
 **/

import React from "react";
import MiscFunctions from "../../helpers/MiscFunctions";
import ElementLayoutHelpers from "../../helpers/ElemLayoutHelpers";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

class WorkflowTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task: this.props.task,
      tasks: this.props.tasks,
      seconds: this.props.task.getTimeStampSeconds,
      key: this.props.counter,
      showInfoCard: this.props.showInfoCardStatus,
      swimlaneTaskFolded: "",
      hover: false,
      clicked: false,
      isOverflowing: false,
      searchKeyword: "",
      isSearching: false,
      infoArrow: true,
      childernLength: false,
    };
    this.swimlaneSegmentWrapperRef = React.createRef();
    this.swimlaneTaskSegmentRef = React.createRef();
    this.taskTooltipStatusRef = React.createRef();
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    const e = this.swimlaneSegmentWrapperRef.current;
    return parseInt(e.getBoundingClientRect().width);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !MiscFunctions.isNull(document.getElementById("info-card-box-id")) &&
      document.getElementById("info-card-box-id").style.display === "none"
    ) {
      document.getElementById("info-card-box-id").style.display = "block";
      this.props.setShowInfoCard(false);
    }

    if (prevState.childernLength !== this.state.childernLength) {
      this.setState({ childernLength: this.state.childernLength });
    }
    if (prevProps.tasks !== this.props.tasks) {
      this.setState({ tasks: this.props.tasks });
    }
    if (prevProps.task !== this.props.task) {
      this.setState({ task: this.props.task });
    }
    if (prevProps.showInfoCardStatus !== this.props.showInfoCardStatus) {
      this.setState({ showInfoCard: this.props.showInfoCardStatus });
    }
    if (prevState.swimlaneTaskFolded !== this.state.swimlaneTaskFolded) {
      this.setState({ swimlaneTaskFolded: this.state.swimlaneTaskFolded });
    }
    if (prevState.hover !== this.state.hover) {
      this.props.hoverParent(this.state.hover);
      this.setState({
        isOverflowing: ElementLayoutHelpers.checkOverflow(
          this.taskTooltipStatusRef
        ),
      });
    }
    if (prevState.clicked !== this.state.clicked) {
      // The state is only transfered if it's true. To ensure triggering of these section in the next run the clicked-state is set to false again
      // By this a change is triggered by the transistion of this state and not it's value
      if (this.state.clicked) {
        this.props.clickParent(this.state.clicked);
        this.props.SetElementClicked(this.props.task.getID);
        this.setState({ clicked: false });
      }
    }
    if (prevProps.searchKeyword !== this.props.searchKeyword) {
      this.setState({ searchKeyword: this.props.searchKeyword });
    }
    if (prevProps.isSearching !== this.props.isSearching) {
      this.setState({ isSearching: this.props.isSearching });
    }

    let e = this.swimlaneSegmentWrapperRef.current;
    e = e.getBoundingClientRect();
    let elemWidth = parseInt(e.width);
    if (snapshot !== elemWidth) {
      this.setState({ infoArrow: elemWidth < 54 ? false : true });
    }
  }

  // Function to identify if the element can be found in global Clicked-Status-Array
  findTaskID(id) {
    let bool = 
    !MiscFunctions.isUndefined(
      this.props.ElementClicked.find((elem) => {
        return elem === id;
      })
    );
    // console.log(bool)
    return bool;
  }

  hasChild(tasks, task) {
    if (!task || !task.id) {
      return false;
    }
    const hasChildren = tasks.some(
      (obj) => obj.parent && obj.parent.id === task.id
    );
    return hasChildren;
  }

  componentDidMount() {
    let { tasks, task } = this.state;

    if (this.hasChild(tasks.Elements, task))
      this.setState({ childernLength: true });

    this.setState({
      isOverflowing: ElementLayoutHelpers.checkOverflow(
        this.taskTooltipStatusRef
      ),
    });

    let e = this.swimlaneSegmentWrapperRef.current;
    e = e.getBoundingClientRect();
    let elemWidth = parseInt(e.width);
    if (elemWidth < 54) this.setState({ infoArrow: false });
  }

  // Function to transfer the hover-state from teh children to the parent. To achieve this, the function is parsed to the children in renderChildren()
  hoverParentDummy(state) {
    this.setState({ hover: state });
  }

  // Function to transfer the clicked-state from teh children to the parent. To achieve this, the function is parsed to the children in renderChildren()
  clickParentDummy(state) {
    this.setState({ clicked: state });
  }

  searchTasks = (tasks, query) => {
    return tasks.filter((task) => {
      return task.label.toLowerCase().includes(query.toLowerCase());
    });
  };

  renderChildren() {
    let Children = this.state.tasks.getChildren(this.state.task);
    if (Children.Elements.length > 0) {
      return Children.Elements.map((child, i) => {
        return (
          <WorkflowTask
            task={child}
            tasks={this.state.tasks}
            videoLength={this.state.task.getDuration}
            counter={i}
            hoverParent={(state) => {
              this.hoverParentDummy(state);
            }}
            clickParent={(state) => {
              this.clickParentDummy(state);
            }}
            SetElementClicked={this.props.SetElementClicked}
            ElementClicked={this.props.ElementClicked}
            Count={this.props.Count}
            SetTaskClicked={this.props.SetTaskClicked}
            selectedTaskInfo={this.props.selectedTaskInfo}
            setShowInfoCard={this.props.setShowInfoCard}
            setClickedTaskWidth={this.props.setClickedTaskWidth}
            showInfoCardStatus={this.props.showInfoCardStatus}
            start={
              child.getTimeStampSeconds - this.state.task.getTimeStampSeconds
            }
            player={this.props.player}
            key={i}
          />
        );
      });
    }
  }

  render() {
    const { searchKeyword, isSearching } = this.state;

    // console.log(this.state.task);
    let childClass = "";
    if (!MiscFunctions.isNull(this.state.task.getParent))
      childClass = " swimlane-sub-segment-wrapper";
    let segmentLevel = this.state.task.getLevel;
    return (
      <div
        className={
          "swimlane-segment-wrapper flex-wrap" +
          childClass +
          " swimlane-" +
          segmentLevel
        }
        key={this.state.key}
        style={{
          left: (this.props.start / this.props.videoLength) * 100 + "%",
          width:
            (Number(this.props.task.getDuration) / this.props.videoLength) *
              100 +
            "%",
        }}
        onClick={() => {
          // if (this.state.showInfoCard) this.setState({ showInfoCard: false });
        }}
        ref={this.swimlaneSegmentWrapperRef}
      >
        {/* {segmentLevel !== "taskgroup" && this.state.showInfoCard === false && ( */}
        {this.state.isOverflowing && this.state.showInfoCard === false && (
          <Tooltip
            id={"my-tooltip-" + this.props.task.getID}
            className="swimlane-process-label-tooltip"
            border="2px solid #19eef2"
          />
        )}

        <div
          data-tooltip-id={"my-tooltip-" + this.props.task.getID}
          data-tooltip-content={this.props.task.getLabel}
          data-tooltip-place="top"
          className={
            "swimlane-task-segment" +
            " swimlane-segment-" +
            segmentLevel +
            " " +
            // style is picked if the Task-ID can be found in the global Clicked-Status-Array
            (this.findTaskID(this.props.task.getID)
              ? "swimlane-task-folded"
              : "") +
            " " +
            (this.state.hover && !this.state.clicked
              ? " swimlane-hover-task-segment"
              : "")
          }
          onMouseOver={(e) => {
            this.setState({ hover: true });
          }}
          onMouseOut={(e) => {
            this.setState({ hover: false });
          }}
          onClick={(e) => {
            if (e.target.classList[0] === "swimlane-task-segment") {
              MiscFunctions.gotoTimestamp(
                this.props.task.getTimeStampSeconds,
                this.props.player
              );
              this.props.setClickedTaskWidth(
                this.swimlaneTaskSegmentRef.current.offsetWidth
              );
            }
            this.setState({ clicked: true });
            this.props.SetElementClicked(null);

            if (this.state.showInfoCard) this.props.setShowInfoCard(false);
          }}
          ref={this.swimlaneTaskSegmentRef}
          style={{
            fontWeight:
              isSearching &&
              this.searchTasks(
                this.state.tasks.getTaskElements.Elements,
                searchKeyword
              ).length
                ? "bold"
                : "normal",
          }}
        >
          {segmentLevel === "taskgroup" && (
            <>
              {this.state.infoArrow && (
                <span
                  className={
                    "swimlane-taskoverflow-info-icon-box" +
                    (this.state.showInfoCard
                      ? " swimlane-taskoverflow-info-icon-box-clicked"
                      : "")
                  }
                  onClick={() => {
                    this.props.setShowInfoCard(true);
                    this.setState({ clicked: true });

                    if (this.state.task.getLevel === "taskgroup") {
                      this.props.SetTaskClicked(
                        this.props.start / this.props.videoLength,
                        this.props.Count
                      );
                      this.props.selectedTaskInfo(this.state.task);
                    
                    }
                    if (
                      this.state.task.getLevel === "maintask" ||
                      this.state.task.getLevel === "subtask"
                    ) {
                      const childPos =
                        this.state.task.getTimeStampSeconds /
                        this.props.player.current.cache_.duration;
                      this.props.SetTaskClicked(childPos, this.props.Count);

                      this.props.selectedTaskInfo(this.state.task);
                    }
                  }}
                >
                  <span className="swimlane-taskoverflow-info-icon"></span>
                </span>
              )}
            </>
          )}
          <span className="swimlane-process-label">
            {/* {segmentLevel !== "taskgroup" &&
              this.state.isOverflowing &&
              this.state.showInfoCard === false && (
                <span className="swimlane-process-label-tooltip">
                  {this.props.task.getLabel}
                </span>
              )} */}
            <span
              className="swimlane-process-label-inside"
              ref={this.taskTooltipStatusRef}
              style={{
                padding: !this.state.infoArrow ? "0" : "",
              }}
            >
              {this.props.task.getLabel}
            </span>
          </span>

          {segmentLevel !== "subtask" &&
            this.state.childernLength &&
            this.state.infoArrow && (
              <span className="swimlane-taskgroup-arrow-down-icon"></span>
            )}
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}

export default WorkflowTask;
